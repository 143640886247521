<template>
  <div class="flex w-full grow flex-col gap-4 overflow-y-auto p-4">
    <ui-event-modal-header :event="relatedEvent" @close="map.closePopup()" />

    <div class="flex w-full grow flex-col gap-y-2">
      <ui-tooltip-tile
        v-for="warning in relatedHazards.warnings"
        :key="warning.id"
        :background-color="warning.backgroundColor"
        :leading-color="warning.leadingColor"
        :title="warning.title"
        :description="warning.description"
        :published-at="warning.publishedAt"
        :icon="warning.icon"
        @icon:clicked="
          toggleSelectedHazard({
            hazardId: warning.id,
            eventId: warning.eventId!,
            hazardType: warning.entityType,
          })
        "
      />
      <ui-tooltip-tile
        v-for="centreOrClosure in relatedHazards.centresAndClosures"
        :key="centreOrClosure.id"
        :background-color="centreOrClosure.backgroundColor"
        :leading-color="centreOrClosure.leadingColor"
        :title="centreOrClosure.title"
        :description="centreOrClosure.description"
        :published-at="centreOrClosure.publishedAt"
        :icon="centreOrClosure.icon"
        @icon:clicked="
          toggleSelectedHazard({
            hazardId: centreOrClosure.id,
            eventId: centreOrClosure.eventId!,
            hazardType: centreOrClosure.entityType,
          })
        "
      />

      <ui-tooltip-tile
        v-for="incident in [...relatedHazards.incidents, ...relatedHazards.naturalHazards]"
        :key="incident.id"
        :background-color="incident.backgroundColor"
        :leading-color="incident.leadingColor"
        :title="incident.title"
        :description="incident.description"
        :published-at="incident.publishedAt"
        :icon="incident.icon"
        @icon:clicked="
          toggleSelectedHazard({
            hazardId: incident.id,
            eventId: incident.eventId!,
            hazardType: incident.entityType,
          })
        "
      />
    </div>

    <ui-map-popup-footer
      left-text="Zoom map"
      :left-callback="
        () =>
          map.flyToPosition({
            zoom: 13,
            center: (pointFeature?.geometry as Point).coordinates as LngLatLike,
          })
      "
      :right-callback="() => toggleSelectedHazard({ hazardId, eventId, hazardType })"
    />
  </div>
</template>

<script setup lang="ts">
import { EventEntity, HazardType, SelectedHazard, SelectedHazardViewType, useHazardsStore, useMapStore } from '@/features/hazards';
import { Point } from 'geojson';
import { LngLatLike } from 'mapbox-gl';
import { computed } from 'vue';

const props = defineProps<{
  hazardType: HazardType;
  hazardId: string;
  eventId: string;
}>();

const map = useMapStore();

const hazardsStore = useHazardsStore();

// Get all the hazards related to the event.
const relatedHazards = computed(() => {
  return hazardsStore.getHazardsByEvent(props.eventId);
});

const relatedEvent = computed<EventEntity | undefined>(() => {
  return hazardsStore.hazards.events.find((event) => event.id === props.eventId);
});

const pointFeature = computed(() => {
  const incident = relatedHazards.value?.incidents.find((incident) => incident.id === map.selectedPopupHazard?.hazardId);
  const warning = relatedHazards.value?.warnings.find((warning) => warning.id === map.selectedPopupHazard?.hazardId);

  return (warning ?? incident)?.pointFeature;
});

const toggleSelectedHazard = (hazard: SelectedHazard) => {
  hazardsStore.toggleSelectHazard({ ...hazard, viewType: SelectedHazardViewType.Modal });
};
</script>
