import { defineStore } from 'pinia';
import { Ref, computed, h, nextTick, reactive, ref, render } from 'vue';

import { BREAKPOINTS, HazardsListWidth } from '@/constants';
import {
  DropdownSelected,
  HAZARD_MARKERS,
  HazardType,
  HazardView,
  MAPBOX_SATELLITE_STYLE,
  MAPBOX_STYLE,
  MapMode,
  MapboxImage,
  MapboxLayer,
  MapboxSource,
  MapboxSourceLayer,
  MapboxStyle,
  PIN_IMAGE,
  SelectedHazard,
  TOTAL_FIRE_BAN_MARKER,
  WESTERN_AUSTRALIA_BOUNDING_BOX,
  buildLoadTileHandler,
  overlaySections,
  updateMarkers,
  updateIncidentAreaMarkers,
  useHazardsStore,
} from '@/features/hazards';

import UiMapPopup from '@/features/hazards/presentation/components/UiMap/UiMapPopup/tooltip/UiMapPopup.vue';
import { booleanContains, booleanOverlap, booleanPointInPolygon, pointOnFeature, polygon } from '@turf/turf';
import { useBreakpoints, useWindowSize } from '@vueuse/core';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, Point } from 'geojson';
import {
  AnyLayer,
  AnySourceData,
  FlyToOptions,
  GeoJSONSource,
  GeolocateControl,
  LngLatBoundsLike,
  LngLatLike,
  Map as MapboxMap,
  Marker,
  Popup,
  PopupOptions,
  ScaleControl,
} from 'mapbox-gl';

import { getSortedHazardFeatures } from '../helpers/get-sorted-hazard-features';

import fdrCenters from '@/scripts/fdr-centers.json';

import { useConfig } from '@/composables';
import { HeaderHeight, ToolbarHeight } from '@/common/helpers/sizes';

const breakpoints = useBreakpoints(BREAKPOINTS);
const isBreakpointMd = breakpoints.greater('md');
const isBreakpointXl = breakpoints.greater('xl');

export const useMapStore = defineStore('mapStore', () => {
  const config = useConfig();

  const interactingWithMarker = ref(false);

  const hazardsStore = useHazardsStore();
  const mapbox = ref<MapboxMap | null>();

  const geolocate = ref<GeolocateControl>();

  const mapElementSize = reactive({
    width: 0,
    height: 0,
  });

  const created = ref(false);
  const loaded = ref(false);

  const globalSources: Record<string, FeatureCollection<Geometry, GeoJsonProperties>> = {};

  const incidentAreaMarkers: Ref<Record<string, Marker>> = ref({});
  const incidentAreaMarkersOnScreen: Ref<Record<string, Marker>> = ref({});

  // used for cluster caching
  const clusterMarkers: Ref<Record<string, Marker>> = ref({});
  const clusterMarkersOnScreen: Ref<Record<string, Marker>> = ref({});

  // visible unclustered markers
  const visibleHazards = ref<Feature<Geometry, GeoJsonProperties>[]>([]);

  // Selected overlays
  const overlayFilters = ref<DropdownSelected>(
    Object.fromEntries(
      overlaySections.map((section) => [section.label, Object.fromEntries(section.items.map((item) => [item.label, false]))])
    )
  );

  const isSomeOverlayFilterActive = computed<boolean>(() =>
    Object.values(overlayFilters.value).some((section) => Object.values(section).some((checked) => checked))
  );

  // List of Fire Ban LGAs
  const activeTotalFireBanLGAs = ref<string[]>([]);
  const lgaToIds = ref<Record<string, { warningId: string; fireBanItemId?: string }>>({});

  const selectedPopup = ref<Popup>();

  const selectedPopupHazard = ref<SelectedHazard | null>(null);

  const panControlsExpanded = ref(false);

  const bearing = ref(0);

  const style = ref<MapboxStyle>(MapboxStyle.default);

  const osmEnabled = ref(false);

  const setMapbox = (newMap: MapboxMap | null) => {
    mapbox.value = newMap;
  };

  const toggleOverlay = (section: string, item: string) => {
    const layerId = overlaySections.find((_section) => _section.label === section)?.items.find((_item) => _item.label === item);
    overlayFilters.value[section][item] = !overlayFilters.value[section][item];
    if (layerId?.overlay) setLayerVisibility(layerId.overlay, overlayFilters.value[section][item]);
  };

  const setVisibleHazards = () => {
    if (!mapbox.value) return;

    const bounds = mapbox.value.getBounds();

    const viewportPolygon = polygon([
      [
        bounds.getNorthEast().toArray(),
        bounds.getNorthWest().toArray(),
        bounds.getSouthWest().toArray(),
        bounds.getSouthEast().toArray(),
        bounds.getNorthEast().toArray(),
      ],
    ]);

    const mapMode = hazardsStore.mapMode;

    let allMarkers: Feature<Geometry, GeoJsonProperties>[] = [];

    switch (mapMode) {
      case MapMode.FireBan:
        allMarkers = mapbox.value.querySourceFeatures(MapboxSource.overlaysCentresSource, {
          sourceLayer: MapboxSourceLayer.lgaCentres,
          // filter: ['in', ['get', 'name'], ['literal', activeTotalFireBanLGAs.value]],
        });
        break;
      case MapMode.FireDanger:
        allMarkers = globalSources[MapboxSource.fireDangerRatingsMarkersSource].features;
        break;
      case MapMode.Default:
        allMarkers = [
          ...globalSources[MapboxSource.hazardsPolygonsSource].features,
          ...globalSources[MapboxSource.hazardsMarkersSource].features,
        ];
    }

    visibleHazards.value = allMarkers.filter((feature) => {
      if (feature.geometry.type === 'Point') {
        return booleanPointInPolygon(feature.geometry.coordinates, viewportPolygon);
      } else if (feature.geometry.type === 'Polygon') {
        return booleanOverlap(feature.geometry, viewportPolygon) || booleanContains(viewportPolygon, feature.geometry);
      } else {
        return false;
      }
    });
  };

  const setSelectedPopupHazard = async (hazard: SelectedHazard | null, fly: boolean = false, openPopup = false) => {
    if (!mapbox.value) return;
    selectedPopup.value?.remove();
    selectedPopupHazard.value = hazard;

    if (!hazard) {
      if (fly) {
        setViewPort(WESTERN_AUSTRALIA_BOUNDING_BOX);
      }
      return;
    }

    if (fly) flyToMarker(hazard.hazardId);

    if (openPopup) {
      let allMarkers: Feature<Geometry, GeoJsonProperties>[] = [];
      switch (hazard.hazardType) {
        case HazardType.TotalFireBans:
          allMarkers = mapbox.value.querySourceFeatures(MapboxSource.overlaysCentresSource, {
            sourceLayer: MapboxSourceLayer.lgaCentres,
            // filter: ['in', ['get', 'name'], ['literal', hazard.hazardId]],
          });
          break;
        case HazardType.FireDangerRating:
          allMarkers = globalSources[MapboxSource.fireDangerRatingsMarkersSource]?.features;
          break;
        case HazardType.Warning:
        case HazardType.Incident:
        case HazardType.Centre:
        case HazardType.Closure:
        case HazardType.PrescribedBurns:
          allMarkers = globalSources[MapboxSource.hazardsMarkersSource]?.features;
          break;
      }

      if (!allMarkers) return;

      const marker = allMarkers?.find((m) => {
        if (hazard.hazardType === HazardType.TotalFireBans) {
          return m.properties?.name === hazard.hazardId;
        }
        return m.properties?.id === hazard.hazardId;
      });

      if (!marker) return;

      const coordinates = (marker.geometry as Point).coordinates as LngLatLike;

      await createPopup({
        type: UiMapPopup,
        coordinates,
        popupElementProps: hazard,
      });
    }
  };

  const flyToMarker = (hazardId: string) => {
    if (!mapbox.value) return;

    const allMarkers = globalSources[MapboxSource.hazardsMarkersSource]?.features;

    if (!allMarkers) return;

    const selectedMarker = allMarkers.find((marker) => marker.properties?.id === hazardId);

    if (!selectedMarker) return;

    flyToPosition({
      center: (selectedMarker.geometry as Point).coordinates as LngLatLike,
      zoom: 13,
    });
  };

  const setCreated = (newCreated: boolean) => {
    if (newCreated) {
      onCreated();
    }
    created.value = newCreated;
  };

  const setViewPort = (newViewPort: LngLatBoundsLike, maxDuration?: number) => {
    mapbox.value?.fitBounds(newViewPort, {
      maxDuration: maxDuration ?? 1000,
    });
  };

  const setBearing = (newBearing: number) => {
    bearing.value = newBearing;
  };

  const addOrSetSource = (id: string, source: AnySourceData) => {
    const existingSource = mapbox.value?.getSource(id) as GeoJSONSource;

    if (source.type === 'geojson' && source.data) globalSources[id] = source.data as FeatureCollection<Geometry, GeoJsonProperties>;

    if (!existingSource) {
      mapbox.value?.addSource(id, source);
      return;
    }

    if (source.type !== 'geojson') {
      return;
    }

    existingSource.setData(source.data as FeatureCollection<Geometry, GeoJsonProperties>);
  };

  const addOrSetSources = (sources: Record<string, AnySourceData>) => {
    Object.entries(sources).forEach(([id, source]) => {
      addOrSetSource(id, source);
    });
  };

  const addLayer = (layer: AnyLayer) => {
    if (!mapbox.value?.getLayer(layer.id)) mapbox.value?.addLayer(layer);
  };

  const addLayers = (layers: AnyLayer[]) => {
    layers.forEach((layer) => {
      addLayer(layer);
    });
  };

  const setStyle = (newValue: MapboxStyle) => {
    setOsmEnabled(false);

    if (newValue == MapboxStyle.satellite && style.value != MapboxStyle.satellite) {
      mapbox.value?.setStyle(MAPBOX_SATELLITE_STYLE);
    } else if (newValue == MapboxStyle.default && style.value != MapboxStyle.default) {
      mapbox.value?.setStyle(MAPBOX_STYLE);
    }

    style.value = newValue;
  };

  const setOsmEnabled = (newValue: boolean) => {
    if (newValue) {
      setLayerVisibility(MapboxLayer.osmLayer, true);
    } else {
      setLayerVisibility(MapboxLayer.osmLayer, false);
    }
    osmEnabled.value = newValue;
  };

  const setMapModeLayer = (mapMode: MapMode) => {
    switch (mapMode) {
      case MapMode.FireBan:
        setLayerVisibility(MapboxLayer.totalFireBanMarkersLayer, true);
        setLayerVisibility(MapboxLayer.totalFireBanPolygonsLayer, true);
        setLayerVisibility(MapboxLayer.totalFireBanPolygonsStrokeLayer, true);

        setLayerVisibility(MapboxLayer.fireDangerRatingsMarkersLayer, false);
        setLayerVisibility(MapboxLayer.fireDangerRatingsPolygonsLayer, false);
        setLayerVisibility(MapboxLayer.fireDangerRatingsPolygonsStrokeLayer, false);

        setLayerVisibility(MapboxLayer.hazardsPolygonsLayer, false);
        setLayerVisibility(MapboxLayer.hazardsPolygonsStrokeLayer, false);
        setLayerVisibility(MapboxLayer.hazardsFireShapePolygonsLayer, false);
        setLayerVisibility(MapboxLayer.hazardsFireShapePolygonsStrokeLayer, false);
        break;
      case MapMode.FireDanger:
        setLayerVisibility(MapboxLayer.totalFireBanMarkersLayer, false);
        setLayerVisibility(MapboxLayer.totalFireBanPolygonsLayer, false);
        setLayerVisibility(MapboxLayer.totalFireBanPolygonsStrokeLayer, false);

        setLayerVisibility(MapboxLayer.fireDangerRatingsMarkersLayer, true);
        setLayerVisibility(MapboxLayer.fireDangerRatingsPolygonsLayer, true);
        setLayerVisibility(MapboxLayer.fireDangerRatingsPolygonsStrokeLayer, true);

        setLayerVisibility(MapboxLayer.hazardsPolygonsLayer, false);
        setLayerVisibility(MapboxLayer.hazardsPolygonsStrokeLayer, false);
        setLayerVisibility(MapboxLayer.hazardsFireShapePolygonsLayer, false);
        setLayerVisibility(MapboxLayer.hazardsFireShapePolygonsStrokeLayer, false);
        break;
      case MapMode.Default:
      default:
        setLayerVisibility(MapboxLayer.totalFireBanMarkersLayer, false);
        setLayerVisibility(MapboxLayer.totalFireBanPolygonsLayer, false);
        setLayerVisibility(MapboxLayer.totalFireBanPolygonsStrokeLayer, false);

        setLayerVisibility(MapboxLayer.fireDangerRatingsMarkersLayer, false);
        setLayerVisibility(MapboxLayer.fireDangerRatingsPolygonsLayer, false);
        setLayerVisibility(MapboxLayer.fireDangerRatingsPolygonsStrokeLayer, false);

        setLayerVisibility(MapboxLayer.hazardsPolygonsLayer, true);
        setLayerVisibility(MapboxLayer.hazardsPolygonsStrokeLayer, true);
        setLayerVisibility(MapboxLayer.hazardsFireShapePolygonsLayer, true);
        setLayerVisibility(MapboxLayer.hazardsFireShapePolygonsStrokeLayer, true);
        break;
    }
  };

  const setLayerVisibility = (id: MapboxLayer, visible: boolean) => {
    mapbox.value?.setLayoutProperty(id, 'visibility', visible ? 'visible' : 'none');
  };

  const addImage = async (id: string, src: string) => {
    if (!mapbox.value) return;

    const image = await new Promise((resolve: (value: HTMLImageElement) => void) => {
      const image = new Image(64, 64);

      /**
       * Fix an issue where the FDR and TFB icons fail with a:
       * `'CanvasRenderingContext2D': The canvas has been tainted by cross-origin data.` error.
       * @see - https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/crossOrigin, https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image
       *
       * Setting the crossOrigin property to 'anonymous' allows the image to be loaded without tainting the canvas.
       * */
      image.crossOrigin = 'anonymous';

      image.onload = () => resolve(image);

      image.src = src;
    });

    if (!mapbox.value || mapbox.value.hasImage(id) || !image) return;
    mapbox.value.addImage(id, image);
  };

  const addImages = async (images: MapboxImage[]) => {
    await Promise.all(images.map(async ({ id, url }) => await addImage(id, url)));
  };

  const initializeImages = async () => {
    /**
     * Should be called when the map is created
     */

    const images = [];
    // initialize images
    images.push(
      ...Object.entries(HAZARD_MARKERS).map(([key, value]) => ({
        id: key,
        url: value,
      }))
    );

    images.push(PIN_IMAGE);
    images.push(TOTAL_FIRE_BAN_MARKER);

    await addImages(images);
  };

  const initializeSources = () => {
    /**
     * Should be called when the map is loaded, but BEFORE initializeLayers
     */

    const sources: Record<string, AnySourceData> = {};

    // Boundaries and icons

    sources[MapboxSource.hazardsMarkersSource] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      cluster: true,
      clusterRadius: 20,
      clusterMaxZoom: 23,
    };

    sources[MapboxSource.hazardsPolygonsSource] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    };

    sources[MapboxSource.fireDangerRatingsPolygonsSource] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    };

    sources[MapboxSource.hazardsFireShapePolygonsSource] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    };

    // Fire danger rating sources

    sources[MapboxSource.fireDangerRatingsMarkersSource] = fdrCenters as AnySourceData;

    sources[MapboxSource.fireDangerRatingsPolygonsSource] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    };

    sources[MapboxSource.osmSource] = {
      type: 'raster',
      tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
      tileSize: 256,
    };

    sources[MapboxSource.addressPinSource] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    };

    // Overlays source
    sources[MapboxSource.overlaysSource] = {
      type: 'vector',
      url: config.value.mapbox.overlaysTileset,
    };

    // Overlays centres source
    sources[MapboxSource.overlaysCentresSource] = {
      type: 'vector',
      url: config.value.mapbox.overlaysCentresTileset,
    };

    // Overlay BOM sources
    sources[MapboxSource.rainRadarSource] = {
      id: MapboxSource.rainRadarSource,
      type: 'custom',
      dataType: 'raster',
      tileSize: 256,
      loadTile: buildLoadTileHandler({
        baseUrl: `${config.value.externalTileBaseUrl}/mapcache/meteye`,
        layer: 'rain-radar',
        transparent: true,
      }),
    };

    sources[MapboxSource.weatherSatelliteSource] = {
      id: MapboxSource.weatherSatelliteSource,
      type: 'custom',
      dataType: 'raster',
      tileSize: 256,
      loadTile: buildLoadTileHandler({
        baseUrl: `${config.value.externalTileBaseUrl}/bom`,
        layer: 'weather-satellite',
        transparent: false,
      }),
    };

    sources[MapboxSource.cycloneTrackSource] = {
      id: MapboxSource.cycloneTrackSource,
      type: 'custom',
      dataType: 'raster',
      tileSize: 256,
      loadTile: buildLoadTileHandler({
        baseUrl: `${config.value.externalTileBaseUrl}/mapcache/meteye`,
        layer: 'cyclone-tracker',
        transparent: true,
      }),
    };

    sources[MapboxSource.thunderstormTrackerSource] = {
      id: MapboxSource.thunderstormTrackerSource,
      type: 'custom',
      dataType: 'raster',
      tileSize: 256,
      loadTile: buildLoadTileHandler({
        baseUrl: `${config.value.externalTileBaseUrl}/mapcache/meteye`,
        layer: 'thunderstorm-tracker',
        transparent: true,
      }),
    };

    sources[MapboxSource.windDirectionSource] = {
      id: MapboxSource.windDirectionSource,
      type: 'custom',
      dataType: 'raster',
      tileSize: 256,
      loadTile: buildLoadTileHandler({
        baseUrl: `${config.value.externalTileBaseUrl}/mapcache/meteye`,
        layer: 'wind-direction',
        transparent: true,
      }),
    };

    sources[MapboxSource.floodCatchmentSource] = {
      id: MapboxSource.floodCatchmentSource,
      type: 'custom',
      dataType: 'raster',
      tileSize: 256,
      loadTile: buildLoadTileHandler({
        baseUrl: `${config.value.externalTileBaseUrl}/bom`,
        layer: 'flood-catchment',
        transparent: true,
      }),
    };

    addOrSetSources(sources);
  };

  const initializeLayers = () => {
    /**
     * Should be called when the map is loaded
     */

    const layers: AnyLayer[] = [];

    layers.push({
      id: MapboxLayer.osmLayer,
      source: MapboxSource.osmSource,
      type: 'raster',
      layout: {
        visibility: 'none',
      },
      paint: {
        'raster-fade-duration': 0,
      },
    });

    // Overlays
    layers.push({
      id: MapboxLayer.dfesRegionsLayer,
      source: MapboxSource.overlaysSource,
      'source-layer': MapboxSourceLayer.dfesRegions,
      type: 'line',
      layout: {
        visibility: 'none',
      },
    });

    layers.push({
      id: MapboxLayer.lgaLayer,
      source: MapboxSource.overlaysSource,
      'source-layer': MapboxSourceLayer.lga,
      type: 'line',
      layout: {
        visibility: 'none',
      },
    });

    layers.push({
      id: MapboxLayer.pastoralLayer,
      source: MapboxSource.overlaysSource,
      'source-layer': MapboxSourceLayer.pastoralStations,
      type: 'line',
      layout: {
        visibility: 'none',
      },
    });

    layers.push({
      id: MapboxLayer.rainRadarLayer,
      source: MapboxSource.rainRadarSource,
      type: 'raster',
      layout: {
        visibility: 'none',
      },
    });

    layers.push({
      id: MapboxLayer.weatherSatelliteLayer,
      source: MapboxSource.weatherSatelliteSource,
      type: 'raster',
      layout: {
        visibility: 'none',
      },
    });

    layers.push({
      id: MapboxLayer.cycloneTrackLayer,
      source: MapboxSource.cycloneTrackSource,
      type: 'raster',
      layout: {
        visibility: 'none',
      },
    });

    layers.push({
      id: MapboxLayer.thunderstormTrackerLayer,
      source: MapboxSource.thunderstormTrackerSource,
      type: 'raster',
      layout: {
        visibility: 'none',
      },
    });

    layers.push({
      id: MapboxLayer.windDirectionLayer,
      source: MapboxSource.windDirectionSource,
      type: 'raster',
      layout: {
        visibility: 'none',
      },
    });

    layers.push({
      id: MapboxLayer.floodCatchmentLayer,
      source: MapboxSource.floodCatchmentSource,
      type: 'raster',
      layout: {
        visibility: 'none',
      },
    });

    // Boundaries and icons

    layers.push({
      id: MapboxLayer.invisibleLayer,
      source: MapboxSource.hazardsMarkersSource,
      type: 'circle',
      paint: {
        'circle-opacity': 0,
        'circle-radius': 0,
      },
    });

    layers.push({
      id: MapboxLayer.addressPinLayer,
      source: MapboxSource.addressPinSource,
      type: 'symbol',
      layout: {
        'icon-image': 'pin',
        'icon-anchor': 'center',
        'icon-size': 0.6,
      },
    });

    layers.push({
      id: MapboxLayer.hazardsPolygonsLayer,
      source: MapboxSource.hazardsPolygonsSource,
      type: 'fill',
      paint: {
        'fill-color': ['case', ['has', 'fill'], ['get', 'fill'], '#231F20'],
        'fill-opacity': ['case', ['has', 'fill-opacity'], ['get', 'fill-opacity'], 0.5],
      },
    });

    layers.push({
      id: MapboxLayer.hazardsPolygonsStrokeLayer,
      source: MapboxSource.hazardsPolygonsSource,
      type: 'line',
      paint: {
        'line-color': ['case', ['has', 'stroke'], ['get', 'stroke'], '#231F20'],
        'line-width': ['case', ['has', 'stroke-width'], ['get', 'stroke-width'], 1],
      },
    });

    // Add the fire shape layers
    layers.push({
      id: MapboxLayer.hazardsFireShapePolygonsLayer,
      source: MapboxSource.hazardsFireShapePolygonsSource,
      type: 'fill',
      paint: {
        'fill-color': ['case', ['has', 'fill'], ['get', 'fill'], '#231F20'],
        'fill-opacity': ['case', ['has', 'fill-opacity'], ['get', 'fill-opacity'], 0.5],
      },
    });

    layers.push({
      id: MapboxLayer.hazardsFireShapePolygonsStrokeLayer,
      source: MapboxSource.hazardsFireShapePolygonsSource,
      type: 'line',
      paint: {
        'line-color': ['case', ['has', 'stroke'], ['get', 'stroke'], '#000000'],
        'line-width': ['case', ['has', 'stroke-width'], ['get', 'stroke-width'], 1],
      },
    });

    // Fire Bans layers

    layers.push({
      id: MapboxLayer.totalFireBanPolygonsLayer,
      source: MapboxSource.overlaysSource,
      'source-layer': MapboxSourceLayer.lga,
      type: 'fill',
      paint: {
        'fill-color': ['case', ['in', ['get', 'name'], ['literal', activeTotalFireBanLGAs.value]], '#D6001C', '#939393'],
        'fill-opacity': ['case', ['in', ['get', 'name'], ['literal', activeTotalFireBanLGAs.value]], 0.2, 0.3],
      },
      layout: {
        visibility: 'none',
      },
    });

    layers.push({
      id: MapboxLayer.totalFireBanPolygonsStrokeLayer,
      source: MapboxSource.overlaysSource,
      'source-layer': MapboxSourceLayer.lga,
      type: 'line',
      paint: {
        'line-color': '#231F20',
        'line-width': 1,
      },
      layout: {
        visibility: 'none',
      },
    });

    layers.push({
      id: MapboxLayer.totalFireBanMarkersLayer,
      source: MapboxSource.overlaysCentresSource,
      'source-layer': MapboxSourceLayer.lgaCentres,
      type: 'symbol',
      filter: ['in', ['get', 'name'], ['literal', activeTotalFireBanLGAs.value]],
      layout: {
        'icon-image': 'total-fire-ban',
        'icon-size': 0.5,
        visibility: 'none',
      },
    });

    // Fire Danger rating layers
    layers.push({
      id: MapboxLayer.fireDangerRatingsPolygonsLayer,
      source: MapboxSource.fireDangerRatingsPolygonsSource,
      type: 'fill',
      paint: {
        'fill-color': ['case', ['has', 'fill'], ['get', 'fill'], '#231F20'],
        'fill-opacity': ['case', ['has', 'fill-opacity'], ['get', 'fill-opacity'], 0.5],
      },
      layout: {
        visibility: 'none',
      },
    });

    layers.push({
      id: MapboxLayer.fireDangerRatingsPolygonsStrokeLayer,
      source: MapboxSource.fireDangerRatingsPolygonsSource,
      type: 'line',
      paint: {
        'line-color': ['case', ['has', 'stroke'], ['get', 'stroke'], '#231F20'],
        'line-width': ['case', ['has', 'stroke-width'], ['get', 'stroke-width'], 1],
      },
      layout: {
        visibility: 'none',
      },
    });

    layers.push({
      id: MapboxLayer.fireDangerRatingsMarkersLayer,
      source: MapboxSource.fireDangerRatingsMarkersSource,
      type: 'symbol',
      layout: {
        'icon-allow-overlap': true,
        'icon-image': 'ew-fdr-no-rating',
        'icon-size': 0.5,
        visibility: 'none',
      },
    });

    addLayers(layers);
  };

  const createPopup = async ({
    type = UiMapPopup,
    coordinates,
    popupElementProps,
    options,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    popupElementProps?: any;
    coordinates: LngLatLike;
    options?: PopupOptions;
  }) => {
    if (!mapbox.value) return;

    const popup = new Popup({ anchor: 'bottom', ...(options ?? {}) }).setLngLat(coordinates).setHTML('<div id="map-popup-content"></div>');
    selectedPopup.value = popup;

    popup.addTo(mapbox.value);

    await nextTick(() => {
      const popupComponent = h(type, { ...popupElementProps, coordinates });

      const element = document.getElementById('map-popup-content')!;
      render(popupComponent, element);

      const boundingRect = element.getBoundingClientRect();

      // At this point in the code, the popup may have rendered outside the map view.
      // First, we must check this, and then if it has, we must correct this by panning
      const margin = 16; // Distance we want between the top of the popup and the map border
      const popupPadding = 16; // Padding of the popup

      // The minimum y-position we want the top of the popup to be at
      const yThreshold = HeaderHeight.value + ToolbarHeight.value + margin;

      let hazardsListWidth = 0;
      if (hazardsStore.activeView === HazardView.Both) {
        if (isBreakpointXl.value) {
          hazardsListWidth = HazardsListWidth.Xl;
        } else if (isBreakpointMd.value) {
          hazardsListWidth = HazardsListWidth.Md;
        }
      }

      const xThreshold = hazardsListWidth + popupPadding + margin;

      if (boundingRect.x <= xThreshold || boundingRect.y <= yThreshold) {
        // If the y (top-left corner) has gone under the toolbar + margin, we must pan the map
        // so that the y is at this minimum y-position
        // This is the distance between the top-left corner and the y threshold
        // (e.g top corner is at y = 16), difference is 16 - 152 = -136
        // In that example, we would move -152 units down (152 units up)
        // The maximum value this can be is 0 (Since boundingRect.y <= yThreshold)

        mapbox.value?.panBy([Math.min(0, boundingRect.x - xThreshold), Math.min(0, boundingRect.y - yThreshold)], {
          duration: 100,
          easing: (time) => time,
        });
      }

      const { width } = useWindowSize();

      if (boundingRect.right > width.value) {
        mapbox.value?.panBy([boundingRect.right + margin - width.value, Math.min(0, boundingRect.y - yThreshold)], {
          duration: 100,
          easing: (time) => time,
        });
      }
    });

    return popup;
  };

  const closePopup = () => {
    if (selectedPopup.value?.isOpen() || selectedPopupHazard.value) {
      setSelectedPopupHazard(null);
      hazardsStore.toggleSelectHazard(null);
    }
  };

  const onCreated = async () => {
    if (!mapbox.value) return;

    mapbox.value.setProjection('mercator');

    mapbox.value.on('rotate', () => {
      setBearing(mapbox.value?.getBearing() ?? 0);
    });

    mapbox.value.on('idle', () => {
      setVisibleHazards();
      updateMarkers();
      updateIncidentAreaMarkers();
    });

    mapbox.value.on('data', (event) => {
      if (event.sourceId === MapboxSource.hazardsMarkersSource && event.isSourceLoaded) {
        setVisibleHazards();
      }
    });

    mapbox.value.on('click', (e) => {
      const target = e.originalEvent.target as HTMLElement;
      if (target.classList.contains('mapboxgl-marker') || target.classList.contains('marker')) return;

      closePopup();
    });

    mapbox.value.on(
      'mouseenter',
      [
        MapboxLayer.fireDangerRatingsPolygonsLayer,
        MapboxLayer.totalFireBanMarkersLayer,
        MapboxLayer.totalFireBanPolygonsLayer,
        MapboxLayer.hazardsPolygonsLayer,
      ],
      () => {
        if (!mapbox.value) return;
        mapbox.value.getCanvas().style.cursor = 'pointer';
      }
    );

    mapbox.value.on(
      'mouseleave',
      [
        MapboxLayer.fireDangerRatingsPolygonsLayer,
        MapboxLayer.totalFireBanMarkersLayer,
        MapboxLayer.totalFireBanPolygonsLayer,
        MapboxLayer.hazardsPolygonsLayer,
      ],
      () => {
        if (!mapbox.value) return;
        mapbox.value.getCanvas().style.cursor = '';
      }
    );

    mapbox.value.on('click', MapboxLayer.totalFireBanMarkersLayer, async (e) => {
      if (!mapbox.value) return;

      const feature = e.features?.at(0);

      if (!feature) return;
      const { name: hazardId } = feature.properties as { name: string };
      const coordinates = (feature?.geometry as Point | undefined)?.coordinates as LngLatLike | undefined;

      if (!coordinates) {
        return;
      }

      setSelectedPopupHazard({ eventId: '', hazardId, hazardType: HazardType.TotalFireBans }, false, false);
      await createPopup({
        type: UiMapPopup,
        coordinates,
        popupElementProps: { eventId: '', hazardId, hazardType: HazardType.TotalFireBans },
      });
    });

    mapbox.value.on('click', MapboxLayer.hazardsPolygonsLayer, async (e) => {
      if (interactingWithMarker.value || !mapbox.value || selectedPopupHazard.value) {
        interactingWithMarker.value = false;
        return;
      }

      const sortedHazards = getSortedHazardFeatures(e.features!);
      const featureToSelect = sortedHazards.at(0);

      if (!featureToSelect) return;

      const {
        id: hazardId,
        entityType: hazardType,
        eventId,
      } = featureToSelect.properties as { eventId: string; id: string; entityType: HazardType };

      const coordinates = e.lngLat;
      if (!coordinates) {
        return;
      }

      const selectedHazard = {
        hazardId,
        hazardType,
        eventId,
      };

      setSelectedPopupHazard(selectedHazard);

      await createPopup({
        coordinates: coordinates,
        popupElementProps: selectedHazard,
      });
    });

    mapbox.value.on('click', MapboxLayer.totalFireBanPolygonsLayer, async (e) => {
      if (!mapbox.value) return;

      const feature = e.features?.at(0);

      if (!feature) return;
      const { name: hazardId } = feature.properties as { name: string };

      const coordinates = e.lngLat;

      if (!coordinates) {
        return;
      }

      setSelectedPopupHazard({ eventId: '', hazardId, hazardType: HazardType.TotalFireBans }, false, false);
      await createPopup({
        type: UiMapPopup,
        coordinates,
        popupElementProps: { eventId: '', hazardId, hazardType: HazardType.TotalFireBans },
      });
    });

    mapbox.value.on('click', MapboxLayer.fireDangerRatingsPolygonsLayer, async (e) => {
      if (!mapbox.value) return;

      const feature = e.features?.at(0);
      if (!feature) return;

      const { id } = feature.properties as { id: string };

      const coordinates = pointOnFeature(feature.geometry as Point).geometry.coordinates as LngLatLike | undefined;
      if (!coordinates) {
        return;
      }

      setSelectedPopupHazard({ eventId: '', hazardId: id, hazardType: HazardType.FireDangerRating }, false, false);
      await createPopup({
        type: UiMapPopup,
        coordinates,
        popupElementProps: { eventId: '', hazardId: id, hazardType: HazardType.FireDangerRating },
      });
    });

    mapbox.value.addControl(new ScaleControl(), 'bottom-right');

    geolocate.value = new GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    });

    mapbox.value.addControl(geolocate.value);

    mapbox.value?.setStyle(MAPBOX_STYLE);
  };

  const setLoaded = (newLoaded: boolean) => {
    if (newLoaded) {
      onLoaded();
    }
    loaded.value = newLoaded;
  };

  const onLoaded = () => {
    initializeImages();

    initializeSources();
    initializeLayers();
  };

  const teardown = () => {
    setCreated(false);
    setLoaded(false);

    setBearing(0);

    if (mapbox.value) {
      mapbox.value.remove();
      setMapbox(null);
    }
  };

  const flyToPosition = (options: FlyToOptions) => {
    mapbox.value?.flyTo({ maxDuration: 1000, ...options });
  };

  const flyToCurrentPosition = () => {
    geolocate.value?.trigger();
  };

  return {
    mapbox,
    setMapbox,
    interactingWithMarker,
    created,
    setCreated,
    loaded,
    overlayFilters,
    isSomeOverlayFilterActive,
    toggleOverlay,
    setLoaded,
    setViewPort,
    bearing,
    style,
    activeTotalFireBanLGAs,
    lgaToIds,
    osmEnabled,
    setStyle,
    addImage,
    addImages,
    teardown,
    flyToPosition,
    flyToCurrentPosition,
    addLayer,
    addLayers,
    addOrSetSource,
    addOrSetSources,
    visibleHazards,
    setVisibleHazards,
    selectedPopupHazard,
    setSelectedPopupHazard,
    createPopup,
    incidentAreaMarkers,
    incidentAreaMarkersOnScreen,
    clusterMarkers,
    clusterMarkersOnScreen,
    setOsmEnabled,
    initializeLayers,
    initializeSources,
    flyToMarker,
    setMapModeLayer,
    geolocate,
    closePopup,
    mapElementSize,
    panControlsExpanded,
  };
});
