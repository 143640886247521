import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './types';

export const routes: RouteRecordRaw[] = [
  {
    path: '',
    name: RouteNames.Hazards,
    component: () => import('@/features/hazards/presentation/views/Hazards.vue'),
    children: [
      {
        path: ':hazardType',
        name: RouteNames.HazardByType,
        component: () => import('@/features/hazards/presentation/views/Hazards.vue'),
      },
      {
        path: ':hazardType/:hazardId',
        name: RouteNames.HazardById,
        component: () => import('@/features/hazards/presentation/views/Hazards.vue'),
        meta: { title: 'Hazard Details' },
      },
    ],
    meta: {
      title: 'Alerts and warnings and incidents',
      description: 'View alerts and warnings in your area.',
    },
  },
  {
    path: '/text-version',
    name: RouteNames.TextVersion,
    component: () => import('@/features/hazards/presentation/views/HazardTextVersion.vue'),
    meta: {
      title: 'Text version of warnings and incidents',
      description: 'View a text only version of alerts and warnings.',
    },
  },
  {
    path: '/text-version/:hazardType/:hazardId',
    name: RouteNames.TextVersionById,
    component: () => import('@/features/hazards/presentation/views/HazardTextSingle.vue'),
    meta: {
      title: 'Text version of warnings and incidents',
      description: 'View a text only version of alerts and warnings.',
    },
  },
  {
    path: '/about',
    component: () => import('@/common/views/about/About.vue'),
    meta: {
      title: 'About this site',
      description: 'Find out more about Emergency WA.',
    },
  },
  {
    path: '/map-icons',
    component: () => import('@/features/hazards/presentation/views/MapIcons.vue'),
    meta: {
      title: 'Map Icons',
    },
  },
  {
    path: '/map-guide',
    component: () => import('@/features/hazards/presentation/views/MapGuide.vue'),
    meta: {
      title: 'Map Guide',
    },
  },
  {
    path: '/:pathMatch(.*)',
    redirect: {
      name: RouteNames.Hazards,
    },
  },
  // dummy routes for external links meta tags
  {
    path: '/aws',
    component: () => {},
    meta: {
      title: 'Australian Warning System',
      description: 'Know the warning levels for bushfire, cyclone, flood, storm and heatwave.',
    },
  },
  {
    path: '/prepare',
    component: () => {},
    meta: {
      title: 'Prepare',
      description: 'Helpful resources to prepare for emergencies.',
    },
  },
  {
    path: '/recovery',
    component: () => {},
    meta: {
      title: 'Recovery',
      description: 'Find recovery and assistance information after an emergency.',
    },
  },
  {
    path: '/faq',
    component: () => {},
    meta: {
      title: 'FAQ',
      description: 'Your Emergency WA questions answered.',
    },
  },
];
