<template>
  <button
    class="flex w-full items-stretch rounded-lg text-left"
    :class="{
      [backgroundColorToTailwindMap[backgroundColor!]]: !!backgroundColor,
      'bg-grey-25': !backgroundColor,
    }"
    @click="$emit('icon:clicked')"
  >
    <div
      class="min-w-2 rounded-l-lg"
      :class="{
        [leadingColorToTailwindMap[leadingColor!]]: !!leadingColor,
      }"
    ></div>

    <div class="flex w-full flex-1 items-center text-clip py-[10px] pl-4 pr-2">
      <div class="flex items-center gap-4">
        <gv-image class="size-10" :src="props.icon" :alt="title + ' icon'" />
        <div class="flex w-[175px] flex-1 flex-col 3xs:w-[214px] 2xs:w-[248px] xs:w-[344px]">
          <gv-type uplifted-variant="subtitle-sm" :text="title" breakpoint="none" />
          <div class="overflow-hidden font-proxima-nova text-[1rem] font-normal leading-[1.4]" v-html="useSanitiseHtml(description)"></div>
          <gv-type uplifted-variant="caption" :text="relativePublishedAgo ?? undefined" breakpoint="none" />
        </div>
      </div>

      <div class="ml-auto flex h-full flex-col justify-center">
        <gv-icon name="add_box" size="md" class="cursor-pointer" />
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import { useSanitiseHtml } from '@/common';
import { UiListTileBackgroundColor, UiListTileLeadingColor } from '@/features/hazards';
import { DateTime } from 'luxon';
import { computed } from 'vue';

const props = defineProps<{
  leadingColor?: UiListTileLeadingColor;
  backgroundColor?: UiListTileBackgroundColor;
  title?: string;
  description?: string;
  publishedAt?: string;
  icon?: string;
}>();

defineEmits<{
  (event: 'icon:clicked'): void;
}>();

const relativePublishedAgo = computed(() => {
  if (!props.publishedAt) {
    return '';
  }

  const parsedDate = DateTime.fromISO(props.publishedAt);

  return parsedDate.toRelative();
});

const leadingColorToTailwindMap = {
  [UiListTileLeadingColor.Black]: 'bg-black',
  [UiListTileLeadingColor.Gray]: 'bg-gray',
  [UiListTileLeadingColor.Blue]: 'bg-blue',
  [UiListTileLeadingColor.Yellow]: 'bg-yellow',
  [UiListTileLeadingColor.Orange]: 'bg-orange',
  [UiListTileLeadingColor.Red]: 'bg-red',
};

const backgroundColorToTailwindMap = {
  [UiListTileBackgroundColor.Pink]: 'bg-pink-50',
  [UiListTileBackgroundColor.Orange]: 'bg-orange-50',
};
</script>
